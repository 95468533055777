define("jeanaoldham/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBI21HtI",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Jeana's Website Coming Soon!\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "jeanaoldham/templates/application.hbs"
    }
  });

  _exports.default = _default;
});